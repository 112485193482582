import request from '@/service/request'
/**
 * app菜单管理(服务管理)
 */

/* app菜单管理 tree接口
* @params/roleId 角色id
*/
export const treeAppRoleApi = (roleId) => {
  return request.post(`/app/appMenu/listMenuTreeByRoleId/${roleId}`)
}

/* app菜单管理 根据角色id获取被分配的app服务 list
* @params/roleId 角色id
*/
export const listAppRoleByRoleIdApi = (roleId) => {
  return request.post(`/app/appMenu/listMenuIdByRoleId/${roleId}`)
}

/* app菜单管理 根据id获取服务详情
* @params/id
*/
export const getAppServiceByIdApi = (id) => {
  return request.post(`/app/appMenu/getById/${id}`)
}

/* app菜单管理 根据id获取服务详情
* @params/id
*/
export const delAppServiceByIdApi = (id) => {
  return request.post(`/app/appMenu/del/${id}`)
}

/* app菜单管理 关联角色菜单
* @params/roleId 角色id
* @params/menuIdList  app服务
*/
export const bindAppServiceByRoleIdApi = (params) => {
  return request.post('/app/appMenu/saveRoleMenu', params)
}

/* app菜单管理 根据服务id批量绑定角色
* @menuId {String} 服务id
* @roleIdList {Array} 角色id
* */
export const bindAppServiceByRoleIdsApi = (params) => {
  return request.post('/app/appMenu/saveMenuRole', params)
}

/* app菜单管理 保存服务 */
export const saveAppServiceApi = (params) => {
  return request.post('/app/appMenu/save', params)
}
