import request from '@/service/request'

/**
 * app菜单分类管理
 */

/* app菜单分类管理 page接口
* @params/keyword 关键字
* @params/pageNum
* @params/pageSize
*/
export const pageAppClassifyApi = (params) => {
  return request.post('/app/appMenuClassify/page', params)
}

/* app菜单分类管理 tree接口
* @params/keyword 关键字
* @params/pageNum
* @params/pageSize
*/
export const treeAppClassifyApi = () => {
  return request.post('/app/appMenuClassify/listTree')
}

/* app菜单分类管理 获取所有app服务接口 */
export const listAppClassifyApi = () => {
  return request.post('/app/appMenuClassify/list')
}

/* app菜单分类管理 根据id获取
*  @params/id
*  */
export const getAppClassifyByIdApi = (id) => {
  return request.post(`/app/appMenuClassify/getById/${id}`)
}

/* app菜单分类管理 保存 */
export const saveAppClassifyApi = (params) => {
  return request.post('/app/appMenuClassify/save', params)
}

/* app菜单分类管理 根据id删除
*  @params/id
*  */
export const delAppClassifyByIdApi = (id) => {
  return request.post(`/app/appMenuClassify/del/${id}`)
}
