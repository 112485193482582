<template>
  <el-dialog
    class="app-classify-dialog"
    :title="`${id?'编辑':'添加'}APP服务分类`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="420px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="分类名称" prop="classifyName">
        <el-input v-model.trim="formData.title" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model.trim="formData.sort" />
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type="primary" @click="save('app服务分类',saveAppClassifyApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getAppClassifyByIdApi, saveAppClassifyApi } from '@/api/app-manage/app-classify-api'

export default {
  name: 'appClassifyDialog',
  mixins: [dialog],
  data () {
    return {
      formData: {
        icon: '',
        id: '',
        img: '',
        remarks: '',
        sort: 0,
        title: '',
        appMenus: []
      },
      rules: {
        title: [{ required: true, message: '请输入分类名称', trigger: 'blur' },
          this.$store.state.commonValidate.limit20WordsObj
        ]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getAppClassifyByIdApi)
      } else {
        this.setInitData()
      }
    })
  },
  methods: { saveAppClassifyApi, getAppClassifyByIdApi }
}
</script>
