<template>
  <el-dialog
    class="app-service-dialog"
    :title="`${id?'编辑':'添加'}APP服务`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="820px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" v-loading="loadData.loading" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="服务图标" prop="icon">
            <single-img-uploader :url.sync="formData.icon" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="img">
            <template #label>
              <tool-tips-group is-flex tips-content="服务在首页显示时，优先显示此图标。">
                服务图标 <br> (首页显示)
              </tool-tips-group>
            </template>
            <single-img-uploader :url.sync="formData.img" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务名称" prop="title">
            <el-input v-model.trim="formData.title" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务类型" prop="classifyId">
            <el-select v-model="formData.classifyId" clearable>
              <el-option v-for="{id,title} in appClassifyList" :key="id" :value="id" :label="title" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-role="['开发人员']">
          <el-form-item label="服务路径" prop="path">
            <el-input v-model.trim="formData.path" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否首页显示" prop="indexFlag">
            <el-radio-group v-model="formData.indexFlag" size="small">
              <el-radio-button :label="1">是</el-radio-button>
              <el-radio-button :label="0">否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="排序" prop="sort">
            <el-input type="number" v-model.trim="formData.sort" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model.trim="formData.remarks" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { listAppClassifyApi } from '@/api/app-manage/app-classify-api'
import { getAppServiceByIdApi, saveAppServiceApi } from '@/api/app-manage/app-service-api'
import store from '@/store'

export default {
  name: 'appServiceDialog',
  mixins: [dialog],
  data () {
    return {
      classifyId: '',
      formData: {
        classifyId: '',
        icon: '',
        id: '',
        img: '',
        indexFlag: 0,
        path: '',
        remarks: '',
        sort: 0,
        title: ''
      },
      rules: {
        title: {
          required: true,
          message: '请输入服务名称',
          trigger: 'blur'
        },
        path: {
          required: true,
          message: '请输入服务的前端路径',
          trigger: 'blur'
        },
        classifyId: {
          required: true,
          message: '请选择服务类型',
          trigger: 'blur'
        },
        icon: {
          required: true,
          message: '请上传服务图标',
          trigger: 'blur'
        }
      },
      appClassifyList: []
    }
  },
  mounted () {
    this.getAppClassifyList()
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getAppServiceByIdApi)
      } else {
        this.formData.classifyId = this.classifyId
        this.setInitData()
      }
    })
  },
  methods: {
    getAppServiceByIdApi,
    saveAppServiceApi,
    async getAppClassifyList () {
      try {
        const res = await listAppClassifyApi()
        this.appClassifyList = res.data
      } catch (e) {
      }
    },
    async handleSave () {
      if (store.state.login.userInfo.roleNameList.includes('开发人员')) {
        await this.save('app服务', saveAppServiceApi)
      } else {
        this.$message.error('只有【开发人员】角色才能添加服务')
      }
    }
  }
}
</script>
