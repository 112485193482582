<template>
  <el-dialog
    class="app-service-dialog"
    :title="`批量绑定多个角色的APP服务（${title}）`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="620px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" v-loading="loadData.loading" :rules="rules" label-width="60px" style="height: 70px;">
      <el-form-item label="角色" prop="roleIdList">
      <tool-tips-group is-flex tips-content="<p class='confirm-message'>批量<b>解除</b>绑定只能通过 <b class='danger'>删除APP服务</b> 实现。</p>">
        <el-select ref="roleSelectRef" v-model="formData.roleIdList" multiple filterable placeholder="请选择角色"
                   style="width: 100%;">
          <el-option
            v-for="{roleName,id} in roleList"
            :label="roleName"
            :value="id"
            :key="id" />
        </el-select>
      </tool-tips-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click='save(`<p class="confirm-message" style="display:inline-block">把 APP服务-<b>${title}</b> 批量绑定到<b class="bg-primary">${formData.roleIdList.length} </b>个角色中</p>`,bindAppServiceByRoleIdsApi)'>批量绑定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { bindAppServiceByRoleIdsApi } from '@/api/app-manage/app-service-api'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'bindAppByRoleIdsDialog',
  mixins: [dialog],
  props: {
    roleList: Array
  },
  data () {
    return {
      formData: {
        menuId: '',
        roleIdList: []
      },
      rules: {
        roleIdList: {
          required: true,
          message: '请先选择角色',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.preTitle = ' '
      if (this.id) {
        this.formData.menuId = this.id
        this.setInitData()
      } else {
        this.$message.error('获取失败，请重试！')
        this.close()
      }
    })
  },
  methods: {
    bindAppServiceByRoleIdsApi
  }
}
</script>
