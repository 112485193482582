<template>
  <!--预设角色固定APP服务-->
  <el-dialog
    class="set-fix-service-dialog"
    title="预设APP服务"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="closed"
    width="1100px"
  >
    <div class="mb-1" style="text-align: right">
      <el-button type="primary" size="small" @click="newRow">新增角色预设</el-button>
    </div>
    <el-form ref="formRef" :model="formData" v-loading="loading" :rules="rules">
      <el-table :data="formData.tableData">
        <el-table-column label="ID" type="index" width="50px" align="center" />
        <el-table-column label="角色名" width="160px">
          <template v-slot="scope">
            <el-form-item
              v-if="scope.row.isEditing&&!noChangeServiceNameList.includes(scope.row.paramName)"
              :prop="`tableData.${scope.$index}.paramName`"
              :rules="rules.paramName">
              <el-select ref="roleREf" size="small" v-model="scope.row.paramName" placeholder="请选择角色,开始APP配置"
                         clearable
                         filterable>
                <el-option v-for="{roleName,id} in roleList" :key="id"
                           v-show="roleName!=='开发人员'"
                           :label="roleName"
                           :value="roleName" />
              </el-select>
            </el-form-item>
            <span v-else>{{ scope.row.paramName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="参数值" min-width="200px">
          <template v-slot="scope">
            <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.paramValue`"
                          :rules="rules.paramValue">
              <el-select ref="roleREf" size="small" v-model="scope.row.fixList" placeholder="请选择角色,开始APP配置"
                         clearable multiple filterable @change="scope.row.paramValue=$event.join(',')">
                <el-option v-for="(title,index) in serviceList" :key="index"
                           :label="title"
                           :value="title" />
              </el-select>
            </el-form-item>
            <span v-else>{{ scope.row.paramValue }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" width="220px">
          <template v-slot="scope">
            <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.remarks`">
              <el-input size="small" v-model="scope.row.remarks" />
            </el-form-item>
            <span v-else>{{ scope.row.remarks }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160px">
          <template v-slot="scope">
            <template v-if="scope.row.isEditing">
              <el-form-item>
                <el-button size="small" @click="cancel(scope)">取消</el-button>
                <el-button v-role="['开发人员']"
                           type="primary" size="small"
                           v-if="scope.row.isEditing" @click="save(scope)">保存
                </el-button>
              </el-form-item>
            </template>
            <template v-else>
              <template v-if="!isEditing">
                <el-button v-role="['开发人员']" size="mini"
                           type="danger"
                           @click="del(deleteParams, scope.row.id,`系统参数/字典-${scope.row.paramName}`)">
                  删除
                </el-button>
                <el-button v-role="['开发人员']" size="mini"
                           type="primary" plain
                           @click="editRow(scope)">编辑
                </el-button>
              </template>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="closed">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import dialog from '@/vue/mixins/dialog'
import { paramsType } from '@/views/pages/system/paramsList/dialog/options'
import Vue from 'vue'
import { deleteParams, getSysDictionaryByKey, addParams } from '@/api/sysParams'

export default {
  name: 'setFixAppServiceDialog',
  mixins: [dialog, tableView],
  props: {
    roleList: {
      type: Array,
      default: () => ([])
    },
    serviceList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      paramsType,
      formData: {
        tableData: []
      },
      noChangeServiceNameList: ['APP首页服务', '其他角色', '通用服务'], // 禁止改名的服务，改了影响appService模块的列表判断。
      isEditing: false
    }
  },
  mounted () {
    this.renderTable()
  },
  methods: {
    deleteParams, // 删除api
    editRow (scope) {
      this.isEditing = true
      scope.row.isEditing = true
      Vue.set(this.formData.tableData, scope.$index, scope.row)
    },
    async renderTable () {
      this.loading = true
      try {
        const res = await getSysDictionaryByKey('fixAppServiceForRole')
        this.formData.tableData = res.data
        // 为以防用户误操作，fixList需要先把换行、空格、中文逗号等处理一下
        this.formData.tableData.map(row => Object.assign(row, {
          isEditing: false,
          fixList: row.paramValue.replace(/[\n|\s|\t|]+/g, '').replaceAll('，', ',').split(',')
        }))
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    newRow () {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          paramValue: '',
          paramName: '',
          paramType: 2, // 禁止修改，因此不显示
          remarks: '设置【APP预设固定服务】。请用英文逗号分隔。',
          paramKey: 'fixAppServiceForRole', // 禁止修改，因此不显示
          isEditing: true,
          fixList: [] // 存选中的服务 需要转化为string
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    async cancel (scope) {
      scope.row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, scope.$index, scope.row)
      await this.renderTable()
    },
    save (scope) {
      const _duplicateKeys = this.formData.tableData.filter(({ paramName }) => paramName === scope.row.paramName)
      if (_duplicateKeys.length > 1) {
        this.$message.error('该角色已设置预设！')
        return
      }
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm('点击确定保存角色预设APP服务。', '保存').then(async () => {
            try {
              await addParams(scope.row)
            } catch (e) {
            }
          }).catch(() => {
            this.cancel(scope)
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.set-fix-service-dialog {
  .el-dialog {
    margin-top: 5vh !important;
  }

  .el-dialog__body {
    padding: 0 15px;

    .el-form {
      padding-right: 0;
    }
  }
}
</style>
