<template>
  <div class="page-container app-service flex-column oh" v-loading="loading">
    <!-- header -->
    <div style="padding: 20px 20px 0;background: #fff;">
      <el-row class="flex-not-shrink" v-permission="['app:service:edit']">
        <el-col :span="12">
          <el-form size="small" inline style="padding: 0;">
            <el-form-item>
              <template #label>
                配置角色的APP服务
                <a class="el-icon-question help-icon" title="操作指引"
                   style="vertical-align: -4px;font-size:20px;color:var(--color-warning);text-decoration: unset;"
                   target="_blank" href="/app-service/app_service_help.png"></a>
              </template>
              <el-select ref="roleREf" v-model="queryInfo.roleId" placeholder="请选择角色,开始APP配置" clearable
                         filterable
                         @change="handleRoleApp">
                <el-option v-for="{roleName,id} in roleList" :key="id"
                           :label="roleName"
                           :value="id" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <!--  保存后的角色禁止编辑服务,需要点击此按钮才能重新编辑  -->
              <el-button v-if="queryInfo.roleId&&!isSettingRole" type="primary" plain @click="isSettingRole=true">
                重新编辑
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col class="flex" :span="12" style="text-align: right;justify-content: flex-end;">
          <span class="mr-1" v-role="['开发人员']">
            <tool-tips-group v-if="!isSettingRole"
                             tips-content="此功能仅【开发人员】角色可看到。用于设置各角色的APP固定服务。" is-flex>
              <el-button type="primary" plain size="small" icon="el-icon-setting"
                         @click="visibleFixServiceDialog=true">
                预设固定APP服务
              </el-button>
            </tool-tips-group>
          </span>
          <el-button type="primary" v-if="!isSettingRole" size="small" @click="visibleDialog=true">添加分类</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- body -->
    <div class="flex-column app-service__body flex flex-1 oh">
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        row-key="id"
        height="auto"
        :default-expand-all=" true"
        :show-select-all="false"
        :tree-props="{children: 'appMenus', hasChildren: 'hasChildren'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column ref="checkBoxRef" type="selection" width="50" align="center"
                         :selectable="handleRowSelectable" />
        <el-table-column prop="title" label="服务名称" width="160" />
        <el-table-column prop="path" label="前端路径" min-width="360px">
          <template v-slot="{ row }">
            {{ row.appMenus ? '--' : row.path }}
          </template>
        </el-table-column>
        <el-table-column label="前端图标" align="center" min-width="90px">
          <template v-slot="{ row }">
            <img v-if="row.icon" class="app-icon" :src="`${$fileUrl}${row.icon}`" alt="">
          </template>
        </el-table-column>
        <el-table-column label="前端图标(首页显示)" align="center" min-width="90px">
          <template v-slot="{ row }">
            <img v-if="row.img" class="app-icon" :src="`${$fileUrl}${row.img}`" alt="">
          </template>
        </el-table-column>
        <el-table-column label="是否首页显示" align="center" width="120px">
          <template v-slot="{ row }">
            <span v-if="row.appMenus" class="font-grey">{{ row.title }}</span>
            <el-tag type="success"
                    v-else-if="fixHomeAppList.length&&fixHomeAppList.includes(row.title)">
              固定显示
            </el-tag>
            <el-switch
              v-else
              v-model="row.indexFlag"
              @change="handleShowIndex($event,row)"
              active-color="var(--color-success)"
              inactive-color="#d7deea"
              :active-value="1"
              active-text=""
              :inactive-value="0"
              inactive-text="" />
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="100px" align="center" />
        <el-table-column prop="remarks" label="备注" min-width="180px" />
        <el-table-column label="操作" width="330">
          <template v-slot="{row}">
            <template v-if="!isSettingRole">
              <el-button v-permission="['app:service:edit']" type="primary" size="mini"
                         @click="showBatchBindDialog(row)">绑定角色
              </el-button>
              <el-button v-permission="['app:service:edit']" type="primary" size="mini" @click="showDialog(row)">编辑
              </el-button>
              <el-button v-permission="['app:service:del']" size="mini" type="danger" @click="del(row)">删除</el-button>
              <el-button v-permission="['app:service:edit']" v-if="row.appMenus" type="primary" size="mini"
                         @click="handleAddChild(row)">
                添加APP服务
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-service__footer flex-not-shrink" v-show="isSettingRole">
      <el-button v-permission="['app:service:edit']" type="primary" @click="bindAppServiceByRoleId">保存配置</el-button>
      <el-button @click="isSettingRole=false">取消</el-button>
    </div>
    <!-- 添加分类 -->
    <app-classify-dialog
      ref="dialogRef"
      :visible.sync="visibleDialog"
      v-if="visibleDialog"
      @on-close="renderTable"
    />
    <!-- 添加服务 -->
    <app-service-dialog
      ref="serviceDialogRef"
      :visible.sync="visibleServiceDialog"
      v-if="visibleServiceDialog"
      @on-close="renderTable"
    />
    <!--  预设固定APP服务 dialog  -->
    <set-fix-service-dialog :visible.sync="visibleFixServiceDialog"
                            v-if="visibleFixServiceDialog"
                            :role-list="roleList"
                            :service-list="serviceList"
                            @on-close="getFixAppList" />
    <!--  根据app服务ID批量绑定服务 dialog  -->
    <batch-bind-app-by-role-ids-dialog
      ref="bindServiceDialogRef"
      :visible.sync="visibleBatchBindDialog"
      v-if="visibleBatchBindDialog"
      :role-list="roleList"
    />
  </div>
</template>

<script>
import { getRoleList } from '@/api/system/role-api'
import { delAppClassifyByIdApi, treeAppClassifyApi } from '@/api/app-manage/app-classify-api'
import {
  bindAppServiceByRoleIdApi,
  delAppServiceByIdApi,
  listAppRoleByRoleIdApi, saveAppServiceApi
} from '@/api/app-manage/app-service-api'
import AppServiceDialog from '@/views/pages/appManage/app-service/components/appServiceDialog.vue'
import AppClassifyDialog from '@/views/pages/appManage/app-service/components/appClassifyDialog.vue'
import { getSysDictionaryByKey } from '@/api/sysParams'
import SetFixServiceDialog from '@/views/pages/appManage/app-service/components/setFixServiceDialog.vue'
import batchBindAppByRoleIdsDialog from '@/views/pages/appManage/app-service/components/batchBindAppByRoleIdsDialog.vue'

export default {
  name: 'appService.',
  components: {
    batchBindAppByRoleIdsDialog,
    SetFixServiceDialog,
    AppClassifyDialog,
    AppServiceDialog
  },
  data () {
    return {
      currentPath: window.location.host,
      queryInfo: {
        roleId: ''
      },
      loading: false,
      roleList: [],
      tableData: [],
      menuIdList: [],
      visibleDialog: false, // 添加/编辑服务分类
      visibleServiceDialog: false, // 添加/编辑服务
      visibleBatchBindDialog: false, // 根据服务id批量绑定角色 dialog
      visibleFixServiceDialog: false, // 添加/编辑根据角色固定app服务
      isSettingRole: false, // 是否正在配置角色app服务
      serviceList: [], // 存二级服务列表，用于设置固定APP服务
      // APP首页、各个角色的app固定服务列表
      fixAppList: [],
      // fixHomeAppList: ['充值', '钱包', '付款', '食堂点餐'],
      fixHomeAppList: [],
      // 通用的固定服務，其他角色必須拼接此數組*
      defaultFixAppList: [],
      // 当前选中角色的固定服务list存在这里
      currentFixAppList: []
    }
  },
  async mounted () {
    // 非开发人员角色，都要开启预设固定app服务的限制
    if (!this.$store.state.login.userInfo.roleNameList.includes('开发人员')) {
      await this.getFixAppList()
    }
    await Promise.all([
      this.getRoleList(),
      this.renderTable()]
    )
  },
  methods: {
    // 获取菜单列表
    async renderTable () {
      this.loading = true
      try {
        const res = await treeAppClassifyApi()
        this.tableData = res.data
        if (this.queryInfo.roleId) {
          await this.handleRoleApp(this.queryInfo.roleId)
        } else {
          if (res.data.length) {
            this.serviceList = []
            res.data.map(classify => {
              if (classify.appMenus?.length) {
                const _result = classify.appMenus.map(({ title }) => title)
                this.serviceList = Array.from(new Set(this.serviceList.concat(_result)))
              }
            })
          }
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getRoleList () {
      try {
        const _query = {
          pageNum: 1,
          pageSize: 1000
        }
        let res = await getRoleList(_query)
        if (res.data.total > _query.pageSize) {
          _query.pageSize = res.data.total
          res = await getRoleList(_query)
        }
        this.roleList = res.data.list
      } catch (e) {
      }
    },
    // table checkbox控制,无角色时禁选
    handleRowSelectable (row) {
      if (this.currentFixAppList.length) {
        return this.isSettingRole && !row.appMenus && !this.currentFixAppList.includes(row.title)
      }
      return this.isSettingRole && !row.appMenus
    },
    handleRoleApp (val) {
      this.isSettingRole = false
      this.menuIdList = []
      this.$refs.table.clearSelection()
      if (val) {
        this.isSettingRole = true
        this.$nextTick(async () => {
          // _fixList，根据角色获取需要预设并禁止取消勾选的app列表
          let _fixList = this.fixAppList.filter(({ paramName }) => paramName === this.$refs.roleREf.selectedLabel) || []
          if (_fixList.length) _fixList = _fixList[0].fixList
          this.currentFixAppList = Array.from(new Set([..._fixList, ...this.defaultFixAppList]))
          try {
            const res = await listAppRoleByRoleIdApi(val)
            // 显示选中
            this.tableData.map(row => {
              if (row?.appMenus?.length) {
                row.appMenus.map(app => {
                  if (res.data.includes(app.id)) {
                    this.$refs.table.toggleRowSelection(app)
                  } else if (this.currentFixAppList.length && this.currentFixAppList.includes(app.title)) {
                    this.$refs.table.toggleRowSelection(app)
                  }
                })
              }
            })
          } catch (e) {
          }
        })
      } else {
        this.currentRoleFixAppList = []
      }
    },
    // 设为首页显示
    handleShowIndex (val, row) {
      this.$confirm(`是否要修改服务【${row.title}】的首页显示状态?`, '修改首页显示状态').then(async () => {
        const _data = {
          id: row.id,
          indexFlag: val
        }
        try {
          await saveAppServiceApi(_data)
          this.$message.success('首页显示状态修改成功!')
        } catch (e) {
        }
      }).catch().finally(async () => {
        await this.renderTable()
      })
    },
    // 获取APP服务固定list
    async getFixAppList () {
      try {
        const res = await getSysDictionaryByKey('fixAppServiceForRole')
        this.fixAppList = res.data
        this.fixAppList.map(item => {
          // 需要想去掉空格，否则匹配不上title
          Object.assign(item, { fixList: item.paramValue.replaceAll(' ', '').split(',') })
        })
        // 获取首页固定的APP服务
        this.fixHomeAppList = this.fixAppList.filter(({ paramName }) => paramName === 'APP首页服务')[0].fixList
        this.defaultFixAppList = this.fixAppList.filter(({ paramName }) => paramName === '通用服务')[0].fixList
      } catch (e) {
      }
    },
    // 多选控制
    handleSelectionChange (val) {
      this.menuIdList = val
    },
    // 关联角色和app服务
    bindAppServiceByRoleId () {
      if (this.menuIdList.length) {
        this.$confirm('点击确定保存APP服务配置', '保存配置').then(async () => {
          try {
            const _data = {
              roleId: this.queryInfo.roleId,
              menuIdList: []
            }
            // 不能传父级(父级带appMenus)
            this.menuIdList.map(({
                                   id,
                                   appMenus
                                 }) => appMenus ? '' : _data.menuIdList.push(id))
            await bindAppServiceByRoleIdApi(_data)
            this.$message.success(`${this.$refs.roleREf.selectedLabel}的APP服务配置保存成功!`)
            this.isSettingRole = false
          } catch (e) {
          }
        })
      } else {
        this.$message.error('请先选择APP服务!')
      }
    },
    // 添加/编辑 分类 dialog
    showDialog (row) {
      // 存在appMenus,是分类,打开分类弹窗;否则打开服务弹窗
      if (row?.appMenus) {
        this.visibleDialog = true
      } else {
        this.visibleServiceDialog = true
      }
      this.$nextTick(() => {
        if (row) {
          if (row?.appMenus) {
            this.$refs.dialogRef.id = row.id
          } else {
            this.$refs.serviceDialogRef.id = row.id
          }
        }
      })
    },
    // 添加子级 dialog
    handleAddChild (row) {
      this.visibleServiceDialog = true
      this.$nextTick(() => {
        this.$refs.serviceDialogRef.classifyId = row.id
      })
    },
    // 编辑固定角色服务的弹窗
    showFixServiceDialog () {
      this.visibleFixServiceDialog = true
    },
    // 根据服务id批量绑定角色 弹窗
    showBatchBindDialog (row) {
      this.visibleBatchBindDialog = true
      this.$nextTick(() => {
        this.$refs.bindServiceDialogRef.id = row.id
        this.$refs.bindServiceDialogRef.title = row.title
      })
    },
    // 删除菜单
    del (row) {
      if (row?.appMenus?.length) {
        this.$message.error('无法删除该分类，因为该分类存在子级服务。请先将分配到该分类下的APP服务迁移到其他分类下，再进行删除操作。')
        return
      }
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          // 删除分类
          if (row?.appMenus) {
            // 分类存在子级时,禁止删除
            await delAppClassifyByIdApi(row.id)
          } else {
            // 删除菜单/服务
            await delAppServiceByIdApi(row.id)
          }
          this.$message.success('删除成功!')
          await this.renderTable()
        } catch (e) {
        }
      }).catch(() => {
        this.$message.warning('已取消删除')
      })
    }
  }
}
</script>
<style lang="scss">
.app-service {
  height: calc(100vh - var(--heard-height));

  .help-icon {
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  .app-icon {
    width: 64px;
    height: 64px;
  }

  .app-service__body {
    background: #fff;

    .el-table {
      width: 100%;
      //隐藏全选框
      .el-table__header {
        .el-table-column--selection {
          .el-checkbox {
            display: none;
          }
        }
      }
    }
  }

  .app-service__footer {
    padding: 10px 0;
    text-align: center;
  }
}
</style>
